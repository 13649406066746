import { t } from 'i18next';
import { stringify } from 'query-string';
import { DefaultOptions as ReactQueryOptions, QueryClient } from 'react-query';

import { HttpStatus, TApiError } from './_http';
import { generatePKCECodes } from './_utils/authHelpers';

type TEnvironment = 'local' | 'development' | 'test' | 'staging' | 'production';

type TTalkJsConfig = {
  appId: string;
  secret: string;
};

const nodeEnv = process.env.REACT_APP_ENV;
function assertNodeEnv(env: string | undefined): asserts env {
  if (!env) {
    throw Error('NODE ENV must be specified');
  }
}

assertNodeEnv(nodeEnv);

export class Config {
  static get apiHost(): string {
    return process.env.REACT_APP_API_HOST;
  }

  static get environment(): TEnvironment {
    return process.env.REACT_APP_ENV as TEnvironment;
  }

  static get sentryDsn(): string {
    return process.env.REACT_APP_SENTRY_DSN;
  }

  static get talkJs(): TTalkJsConfig {
    return {
      appId: process.env.REACT_APP_TALKJS_APP_ID,
      secret: process.env.REACT_APP_TALKJS_SECRET,
    };
  }

  static get bookAMeetingConfig() {
    return {
      appapikey: process.env.REACT_APP_BOOK_A_MEETING_KEY,
      appid: process.env.REACT_APP_BOOK_A_MEETING_ID,
    };
  }

  static get bookAMeetingUrl() {
    return process.env.REACT_APP_BOOK_A_MEETING_URL;
  }

  static reactQuery(queryClient: QueryClient): ReactQueryOptions {
    const handleUnauthorizedCalls = (error: TApiError) => {
      if (error.statusCode === HttpStatus.Unauthorized && !window.location.href.includes('/auth')) {
        // do authenticate call again, redirect to login onError
        queryClient.invalidateQueries('authenticate');
      }
    };
    return {
      mutations: {
        onError: handleUnauthorizedCalls,
        retry: false,
      },
      queries: {
        onError: handleUnauthorizedCalls,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 60000,
      },
    };
  }

  static get registerUrl(): string {
    return process.env.REACT_APP_EVENT_WORKS_REGISTER_URL;
  }

  static get updateUrl(): string {
    return process.env.REACT_APP_EVENT_WORKS_UPDATE_URL;
  }

  static get youtubeLink(): string {
    return 'https://www.youtube.com';
  }

  static get showCommunity(): boolean {
    return process.env.REACT_APP_SHOW_COMMUNITY === 'false';
  }

  static socialMediaUrls(url: string): {
    facebook: string;
    linkedin: string;
    twitter: string;
  } {
    const text = encodeURIComponent(`${t('EVENT.ENGAGE.TWITTER_TXT')}`);
    const encodedUrl = encodeURIComponent(url);
    return {
      facebook: `https://www.facebook.com/share.php?u=${encodedUrl}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`,
      twitter: `https://twitter.com/intent/tweet/?url=${encodedUrl}&text=${text}`,
    };
  }

  static get oidcConfiguration() {
    return {
      authority: process.env.REACT_APP_AUTHORITY,
      client_id: process.env.REACT_APP_CLIENT_ID,
      code_challenge_method: 'S256',
      redirect_uri: `${window.location.origin}/login/callback`,
      response_mode: 'query',
      response_type: 'code',
      scope: process.env.REACT_APP_SCOPE,
    };
  }

  static async getLoginRedirectUrl(): Promise<string> {
    const { codeChallenge, codeVerifier } = await generatePKCECodes();

    return `${process.env.REACT_APP_AUTHORITY}/authorize?${stringify(
      {
        client_id: Config.oidcConfiguration.client_id,
        code_challenge: codeChallenge,
        code_challenge_method: Config.oidcConfiguration.code_challenge_method,
        redirect_uri: Config.oidcConfiguration.redirect_uri,
        response_mode: Config.oidcConfiguration.response_mode,
        response_type: Config.oidcConfiguration.response_type,
        scope: Config.oidcConfiguration.scope,
        state: [codeVerifier, window.location.pathname],
      },
      { arrayFormat: 'separator' },
    )}`;
  }
}
