import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Spinner } from '../../../_shared';
import Carousel from '../../../ec/carousel/Carousel';
import PageBanner from '../../../ec/pageBanner/PageBanner';
import { useGetHighlights } from '../../_queries/useGetHighlights';

const Highlights: FC = () => {
  const { t } = useTranslation();
  const { data: highlights, isLoading } = useGetHighlights();

  if (isLoading) return <Spinner />;
  return (
    <Carousel
      autoPlay
      emptyLabel={t('HOME.HIGHLIGHTS_EMPTY')}
      slides={(highlights || []).map(highlight => {
        if (highlight?.event) {
          const { id, image, title, startDate, endDate, shortDescription } = highlight.event;

          return (
            <PageBanner
              description={shortDescription}
              endDate={endDate}
              image={image}
              key={id}
              label={t('EVENTS.VIEW_EVENT')}
              link={`/events/${id}/overview`}
              startDate={startDate}
              title={title}
              type=""
            />
          );
        }
        return (
          <PageBanner
            description={highlight.summary}
            image={highlight.image}
            key={highlight.id}
            label={t('EVENTS.VIEW_EVENT')}
            link={highlight.url}
            title={highlight.title}
            type=""
          />
        );
      })}
    />
  );
};

export default Highlights;
